import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, SearchFilterDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-filter-item-form',
  templateUrl: './filter-item-form.component.html',
  styleUrl: './filter-item-form.component.scss',
})
export class FilterItemFormComponent extends BaseForm<SearchFilterDto> implements OnInit {
  @Input() moduleKeyword;
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'FILTER');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat({ ...this.formGroup.getRawValue()?.filterBuilder })
      : this.getChangedFormValues({ ...this.formGroup.getRawValue()?.filterBuilder }).updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ filterBuilder: { ...data } });
    this.data = data;

    //   this.formGroup?.patchValue({
    //     key: cleanNotAlphaNumericUnderScore(this.formGroup?.controls?.name?.value)
    //   })
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      filterBuilder: new FormControl(null),
      // name: new FormControl(null, [Validators.required,Validators.pattern(/^[a-zA-Z_$][a-zA-Z0-9_\s$]*$/)]),
      // key: new FormControl(null, ),
      // type: new FormControl(null, Validators.required),
      // options: new FormControl(null),
      // fieldCategory: new FormControl('TEMPLATE')
    });
  }
}
