<app-filter-builder
  [moduleKeyword]="moduleKeyword"
  [formControl]="$any(formGroup.controls.filterBuilder)"
  [control]="formGroup.controls.filterBuilder"
>
</app-filter-builder>
<div class="flex flex-row-reverse justify-content-between align-items-center mt-2">
  <div class="flex flex-row-reverse gap-2">
    @if (showSaveButton) {
      <app-button [action]="submitButtonAction"></app-button>
    }
    @if (showSaveButton) {
      <app-button [action]="submitSaveButtonAction"></app-button>
    }
    @if (showInPlaceButton) {
      <app-button [action]="submitInPlaceButtonAction"></app-button>
    }
    @if (fieldViewMode == 'create') {
      <app-button [action]="cancelButtonAction"></app-button>
    }
  </div>
  @if (fieldViewMode == 'create') {
    <app-switch-input
      [label]="'general.messages.create another record' | translate"
      [name]="'inPlaceCreate'"
      [labelWidthFit]="true"
      [control]="inPlaceCreateControl"
      [viewMode]="'create'"
      [styleClass]="'mb-0'"
    >
    </app-switch-input>
  }
</div>
